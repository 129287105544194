import React, { useEffect } from 'react'

import SEO from '../components/seo/seo'
import { useGlobalContext } from '../state/global'
import usePageStatus from '../hooks/usePageStatus/usePageStatus'

// Component
export default ({ transitionStatus }) => {
  const [, dispatchGlobal] = useGlobalContext()

  useEffect(() => {
    dispatchGlobal({
      type: 'setPage',
      newPage: 'index',
    })

    dispatchGlobal({
      type: 'setIsFilterOn',
      newIsFilterOn: false,
    })
  }, [])

  usePageStatus(transitionStatus, 'index')

  return <SEO />
}
